@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
    font-family: "Helvetica Neue LT";
    font-weight: 300;
    src: url("../fonts/Helvetica Neue LT Std/Helvetica Neue LT Std 45 Light.woff2") format("woff2"),
        url("../fonts/Helvetica Neue LT Std/Helvetica Neue LT Std 45 Light.woff") format("woff");
}

@font-face {
    font-family: "Helvetica Neue LT";
    font-weight: 400;
    src: url("../fonts/Helvetica Neue LT Std/Helvetica Neue LT Std 55 Roman.woff2") format("woff2"),
        url("../fonts/Helvetica Neue LT Std/Helvetica Neue LT Std 55 Roman.woff") format("woff");
}

@font-face {
    font-family: "Helvetica Neue LT";
    font-weight: 500;
    src: url("../fonts/Helvetica Neue LT Std/Helvetica Neue LT Std 65 Medium.woff2") format("woff2"),
        url("../fonts/Helvetica Neue LT Std/Helvetica Neue LT Std 65 Medium.woff") format("woff");
}

@font-face {
    font-family: "Helvetica Neue LT";
    font-weight: 700;
    src: url("../fonts/Helvetica Neue LT Std/Helvetica Neue LT Std 75 Bold.woff2") format("woff2"),
        url("../fonts/Helvetica Neue LT Std/Helvetica Neue LT Std 75 Bold.woff") format("woff");
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @apply bg-accent-light;
}

*:focus {
    outline: none !important;
}

.tooltip {
    @apply hidden group-hover:flex;
    @apply absolute bottom-0.5 left-1/2 transform -translate-y-full -translate-x-1/2;
    @apply bg-black/75 text-xs text-white justify-center py-1 px-2 rounded;
}

.tooltip:before {
    @apply absolute top-full left-1/2 w-0 transform -translate-x-1/2;
    @apply border-t-4 border-t-black/75;
    @apply border-l-4 border-l-transparent;
    @apply border-r-4 border-r-transparent;
    content: "";
}
